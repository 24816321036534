<template>
  <section
    class="invoice-preview-wrapper"
    style="position: relative"
  >
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="payment === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'facture-list' }"
        >
          invoice List
        </b-link>
        for other invoice.
      </div>
    </b-alert>
    <div
      v-if="isLoading === true"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <b-row
      v-if="payment"
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          v-if="payment"
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <div
            class="d-flex justify-content-center flex-md-row flex-column invoice-spacing mt-0"
          >
            <div style="color: black">
              <div class="logo-wrapper">
                <img
                  src="@/assets/images/logo/logo.png"
                  width="300px"
                  height="100%"
                >
                <h3 class="text-primary invoice-logo" />
              </div>
            </div>
          </div>

          <b-card-body class="d-flex flex-row justify-content-between">
            <div
              style="color: black"
            >
              <div class="ml-1">
                <h1
                  style="color: black; font-size: 30px"
                >Paiement N°: {{ payment.id }}</h1>
              </div>
              <div>
                <h3
                  class="ml-1"
                  style="color: black"
                >
                  Date:
                  <span
                    style="color: black"
                  >
                    {{ formatFn(payment.created_at) }}</span>
                </h3>
              </div>

            </div>
            <div
              class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 mr-1"
            >
              <!-- Header: Left Content -->

              <!-- Header: Right Content -->
              <div
                class="mb-2 mt-0 ml-2"
                style="
                  border: 2px solid black;
                "
              >

                <b-card-text
                  class="ml-2 mr-2 mt-2 mb-1 h4"
                  style="color:black"
                >
                  <strong> {{ company.name }} : </strong>Société de livraison des colis
                </b-card-text>
                <b-card-text
                  class="ml-2 h4 mr-2 mb-1"
                  style="color:black"
                >
                  <strong>Matricule fiscale : </strong> {{ company.tax_identification_number }}
                </b-card-text>
                <b-card-text
                  class="ml-2 mr-2 mb-1 h4"
                  style="color:black"
                >
                  <strong> Adresse :</strong> {{ company.address }}
                </b-card-text>
                <b-card-text
                  class="ml-2 mr-2 mb-1 h4"
                  style="color:black"
                >
                  <strong>Tel :</strong> {{ company.phone1 }}
                </b-card-text>
                <b-card-text
                  class="ml-2 mr-2 mb-1 h4"
                  style="color:black"
                >
                  <strong>Livreur : </strong> {{ payment.delivery_man.username }}
                </b-card-text>
              </div>
            </div>

          </b-card-body>

          <!-- Colis livré -->
          <b-card-body class="invoice-padding pt-0">
            <div class="d-flex justify-content-between">
              <p
                class="
            font-weight-bold
            text-dark
            h5
            mb-1"
              >
                <feather-icon
                  class="mr-1"
                  icon="FolderPlusIcon"
                  style="width: 24px;height: 24px;"
                  color="green"
                />
                <span style="color:green;font-size: 20px;">Colis livré {{ payment.delivred_orders_count }}</span>
              </p>
              <div>
                <p
                  class="
            font-weight-bold
            text-dark
            h5
            mb-1"
                >
                  <feather-icon
                    class="mr-1"
                    icon="CheckSquareIcon"
                    style="width: 20px;height: 20px;"
                    color="green"
                  />
                  <span style="color:green;font-size: 20px;">Prix de colis livrés : {{ payment.delivred_price.toFixed(3) }} DT</span>
                </p>
                <!-- <hr>
                <p
                  class="
            font-weight-bold
            text-dark
            h5
            mb-1"
                >
                  <feather-icon
                    class="mr-1"
                    icon="TruckIcon"
                    style="width: 20px;height: 20px;"
                    color="green"
                  />
                  <span style="color:green;font-size: 20px;">Prix de frais de livraison : {{ payment.total_delivery_price.toFixed(3) }}</span>
                </p> -->

              </div>

            </div>
            <b-table-lite
              class="ta"
              responsive
              :items="delivredOrders"
              :fields="['code','fournisseur','gouvernorat','delegation','tel','destinataire','prix']"
            >
              <template
                #cell(code)="data"
              >
                <h5>{{ data.item.code }}</h5>
                <span
                  v-if="data.item.is_an_exchange"
                  style="color:red ; font-weight: bold;"
                > Echange </span>
              </template>
              <template #cell(taskDescription)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.taskTitle }}{{ data.item.taskTitle }}
                </b-card-text>
                <b-card-text class="text-nowrap">
                  {{ data.item.taskDescription }}
                </b-card-text>
              </template>
            </b-table-lite>

          </b-card-body>
          <!-- Colis retour -->
          <b-card-body class="invoice-padding pt-0">
            <div class="d-flex justify-content-between">

              <p class="font-weight-bold text-dark h5 mb-1">
                <feather-icon
                  icon="FolderMinusIcon"
                  style="width: 24px;height: 24px;"
                  class="mr-1"
                  color="red"
                />
                <span style="color:red;font-size: 20px;">Colis retour {{ payment.return_orders_count }}</span>
              </p>
              <p
                class="
            font-weight-bold
            text-dark
            h5
            mb-1"
              >
                <feather-icon
                  class="mr-1"
                  icon="DollarSignIcon"
                  style="width: 20px;height: 20px;"
                  color="red"
                />
                <span style="color:red;font-size: 20px;">Prix de colis retour : {{ payment.return_price.toFixed(3) }} DT</span>
              </p>
            </div>
            <b-table-lite
              class="ta"
              responsive
              :items="returnOrders"
              :fields="['code','fournisseur','gouvernorat','delegation','tel','destinataire','prix']"
            >
              <template
                #cell(code)="data"
              >
                <h5>{{ data.item.code }}</h5>
                <span
                  v-if="data.item.is_an_exchange"
                  style="color:red ; font-weight: bold;"
                > Echange </span>
              </template>
              <template #cell(taskDescription)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.taskTitle }}{{ data.item.taskTitle }}
                </b-card-text>
                <b-card-text class="text-nowrap">
                  {{ data.item.taskDescription }}
                </b-card-text>
              </template>
            </b-table-lite>

          </b-card-body>

          <b-card-body class="invoice-padding pt-0">
            <div class="d-flex justify-content-between">

              <p class="font-weight-bold text-dark h5 mb-1">
                <feather-icon
                  icon="FolderMinusIcon"
                  style="width: 24px;height: 24px;"
                  class="mr-1"
                  color="red"
                />
                <span style="color:red;font-size: 20px;">Colis Echange {{ payment.exchange_orders_count }}</span>
              </p>
            </div>
            <b-table-lite
              class="ta"
              responsive
              :items="exchangeOrders"
              :fields="['code','fournisseur','gouvernorat','tel','destinataire','prix']"
            >
              <template #cell(taskDescription)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.taskTitle }}{{ data.item.taskTitle }}
                </b-card-text>
                <b-card-text class="text-nowrap">
                  {{ data.item.taskDescription }}
                </b-card-text>
              </template>
            </b-table-lite>

          </b-card-body>
          <!-- Invoice Description: Total -->

          <b-card-body
            class="d-flex justify-content-between mt-5"
          >
            <!-- Col: Total -->

            <div class="mb-5 ml-1">
              <h3 style="color: black; font-size: 25px">
                Signature
              </h3>
            </div>
            <div>

              <h1 style="color: black; font-size: 25px" />

            </div>

          </b-card-body>

          <hr class="invoice-spacing">

          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold" />
            <span />
          </b-card-body>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            :class="{ 'disabled-cursor': isLoading }"
            :disabled="isLoading"
            @click="printInvoice"
          >
            Imprimer
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import storeAuth from '@/store/store'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
  },
  data() {
    return {
      isLoading: false,
      payment: {},
      delivredOrders: [],
      returnOrders: [],
      exchangeOrders: [],
      campany: {},
    }
  },
  created() {
    this.id = this.$route.params.id
    this.company = storeAuth.state.company
    this.getPayment()
  },
  methods: {
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')} | ${h}`
    },
    async getPayment() {
      this.isLoading = true
      await axios.get(`/api/payments/detail/${this.$route.params.id}`).then(response => {
        this.payment = response.data
        const { delivred_orders } = this.payment
        const { return_orders } = this.payment
        const { exchange_orders } = this.payment

        for (let i = 0; i < delivred_orders.length; i += 1) {
          const data = {
            code: delivred_orders[i].id,
            gouvernorat: delivred_orders[i].governorate,
            delegation: null,
            fournisseur: delivred_orders[i].supplier.username,
            tel: delivred_orders[i].phone1,
            destinataire: delivred_orders[i].name,
            adresse: delivred_orders[i].address,
            prix: delivred_orders[i].price_ttc,
            prix_de_livraison: delivred_orders[i].price_delivery,
            is_an_exchange: delivred_orders[i].is_an_exchange,
          }
          if (delivred_orders[i].delegation != null) {
            data.delegation = delivred_orders[i].delegation
          }
          this.delivredOrders.push(data)
        }

        for (let i = 0; i < return_orders.length; i += 1) {
          const data = {
            code: return_orders[i].id,
            gouvernorat: return_orders[i].governorate,
            delegation: null,
            fournisseur: return_orders[i].supplier.username,
            tel: return_orders[i].phone1,
            destinataire: return_orders[i].name,
            adresse: return_orders[i].address,
            prix: return_orders[i].price_ttc,
            is_an_exchange: return_orders[i].is_an_exchange,
          }
          if (return_orders[i].delegation != null) {
            data.delegation = return_orders[i].delegation
          }
          this.returnOrders.push(data)
        }
        this.isLoading = false
        for (let i = 0; i < exchange_orders.length; i += 1) {
          const data = {
            code: exchange_orders[i].id,
            gouvernorat: exchange_orders[i].governorate,
            fournisseur: exchange_orders[i].supplier.username,
            tel: exchange_orders[i].phone1,
            destinataire: exchange_orders[i].name,
            adresse: exchange_orders[i].address,
            prix: exchange_orders[i].price_ttc,
            is_an_exchange: exchange_orders[i].is_an_exchange,
          }
          this.exchangeOrders.push(data)
        }
      })
    },
  },
  setup() {
    const printInvoice = () => {
      window.print()
    }

    return {
      printInvoice,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
table,
td,
th {
  padding: 10px;
  text-align: left;
  border: 1px solid black;
}
footer {
  bottom: 0;
  height: auto; /* Footer height */
}
table {
  width: 100%;
  border-collapse: collapse;
}
.mycolor{
    background-color:#01B2FE ;
  }
.contact {
  background-color: #01B2FE;
  padding: 10px;
  border-radius: 20px;
}
</style>

<style lang="scss">
@media print {
  html, body {
    height: 99%;
  }
  .ta {
    overflow-x: visible;
    font-size: 20px;
    color: black;
  }

  footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto; /* Footer height */
  }

  .table thead th {
    border: 2px solid black;
  }

  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  .mycolor{
    background-color:#01B2FE ;
    -webkit-print-color-adjust: exact;
  }
  .contact {
    background-color: #01B2FE;
    padding: 20px;
    border-radius: 25px;
    -webkit-print-color-adjust: exact;
  }
  footer footer-light footer-static {
    display: inline-block;
  }
  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  table,
  td,
  th {
    padding: 10px;
    text-align: left;
    border: 1px solid black;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
}
</style>
